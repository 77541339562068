var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"dashhead"},[_c('div',{staticClass:"dashhead-titles"},[_c('h6',{staticClass:"dashhead-subtitle"},[_vm._v(_vm._s(_vm.subTitle))]),_c('h3',{staticClass:"dashhead-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")])])]),_c('hr',{staticClass:"my-3"}),_c('sgv-form',{attrs:{"method":_vm.method,"options":_vm.options},on:{"update:method":function($event){_vm.method=$event}}},[_c('div',[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ชื่อ","validations":[
            {text: 'required!', value: _vm.$v.formData.name.$dirty && _vm.$v.formData.name.$error}
          ]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Board ID","validations":[
            {text: 'required!', value: _vm.$v.formData.boardId.$dirty && _vm.$v.formData.boardId.$error}
          ]},model:{value:(_vm.formData.boardId),callback:function ($$v) {_vm.$set(_vm.formData, "boardId", $$v)},expression:"formData.boardId"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Trello Key","validations":[
            {text: 'required!', value: _vm.$v.formData.trelloKey.$dirty && _vm.$v.formData.trelloKey.$error}
          ]},model:{value:(_vm.formData.trelloKey),callback:function ($$v) {_vm.$set(_vm.formData, "trelloKey", $$v)},expression:"formData.trelloKey"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Trello Token","validations":[
            {text: 'required!', value: _vm.$v.formData.trelloToken.$dirty && _vm.$v.formData.trelloToken.$error}
          ]},model:{value:(_vm.formData.trelloToken),callback:function ($$v) {_vm.$set(_vm.formData, "trelloToken", $$v)},expression:"formData.trelloToken"}})],1),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('sgv-input-check',{attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ใช้งาน","inline":""},model:{value:(_vm.formData.isActive),callback:function ($$v) {_vm.$set(_vm.formData, "isActive", $$v)},expression:"formData.isActive"}})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }